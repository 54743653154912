import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Catalog, CatalogDetail, MappedValue } from '@entity/catalog';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private readonly baseUrl = `api/1.0/mandanten`;

  constructor(private httpClient: HttpClient) {}

  getCatalogs(mandantId: number): Observable<Catalog[]> {
    return this.httpClient.get<Catalog[]>(`${this.baseUrl}/${mandantId}/catalogs`);
  }

  getCatalogDetails(mandantId: number, catalogId: number): Observable<CatalogDetail[]> {
    return this.httpClient.get<CatalogDetail[]>(`${this.baseUrl}/${mandantId}/catalogs/${catalogId}`);
  }

  updateCatalogDetails(mandantId: number, catalogId: number, value: CatalogDetail[]): Observable<CatalogDetail[]> {
    return this.httpClient.put<CatalogDetail[]>(`${this.baseUrl}/${mandantId}/catalogs/${catalogId}`, value);
  }

  getVariableMappings(mandantId: number, catalogId: number, detailId = ''): Observable<MappedValue[]> {
    return this.httpClient.get<MappedValue[]>(
      `${this.baseUrl}/${mandantId}/catalogs/${catalogId}/variables/${detailId}`
    );
  }

  updateVariableMappings(
    mandantId: number,
    catalogId: number,
    detailId: number,
    mappedValues: MappedValue[]
  ): Observable<MappedValue[]> {
    return this.httpClient.put<MappedValue[]>(
      `${this.baseUrl}/${mandantId}/catalogs/${catalogId}/variables/${detailId}`,
      mappedValues
    );
  }

  getMeasureMappings(mandantId: number, catalogId: number, detailId = ''): Observable<MappedValue[]> {
    return this.httpClient.get<MappedValue[]>(
      `${this.baseUrl}/${mandantId}/catalogs/${catalogId}/measures/${detailId}`
    );
  }

  updateMeasureMappings(
    mandantId: number,
    catalogId: number,
    detailId: number,
    mappedValues: MappedValue[]
  ): Observable<MappedValue[]> {
    return this.httpClient.put<MappedValue[]>(
      `${this.baseUrl}/${mandantId}/catalogs/${catalogId}/measures/${detailId}`,
      mappedValues
    );
  }

  resetCatalogMapping(mandantId: number, catalogId: number): Observable<unknown> {
    return this.httpClient.post(`${this.baseUrl}/${mandantId}/catalogs/${catalogId}/reset-mapping`, {});
  }

  downloadMappingCatalog(mandantId: number, catalogId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.baseUrl}/${mandantId}/catalogs/${catalogId}/mapping-xslx-export`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
