import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from '@service/context.service';
import { TreeNode } from 'primeng/api';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CatalogDetail } from '@entity/catalog';
import { MainDataService } from '@service/main-data.service';
import { Column } from '@shared/table/column';
import { CatalogService } from '@service/catalog.service';
import { v4 as uuid } from 'uuid';

@Injectable()
export class MappingCatalogDetailTableService extends TableService<TreeNode> {
  constructor(
    private catalogService: CatalogService,
    private mainDataService: MainDataService,
    private contextService: ContextService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  createEntry(): TreeNode {
    return {
      children: [],
      data: {
        deletable: true,
        mappedValues: {},
      },
    } as TreeNode;
  }

  getData(): Observable<{ entities: TreeNode[] }> {
    if (this.activatedRoute.snapshot.data.isMandantSection) {
      return this.mainDataService
        .getCatalogDetails(this.activatedRoute.snapshot.params.id)
        .pipe(map((result) => this.transform(result)))
        .pipe(map((entities) => ({ entities })));
    } else {
      return this.catalogService
        .getCatalogDetails(this.contextService.getMandantIdInstant(), this.activatedRoute.snapshot.params.id)
        .pipe(map((result) => this.transform(result)))
        .pipe(map((entities) => ({ entities })));
    }
  }

  isEditable(e: TreeNode, column: Column): boolean {
    return e.data.detail || column.field === 'id' || column.field === 'name';
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: TreeNode[]): Observable<TreeNode[]> {
    const catalogDetails = value.map((v) => this.getBatchUpdatedCatalogDetails(v));

    if (this.activatedRoute.snapshot.data.isMandantSection) {
      return this.mainDataService
        .updateCatalogDetails(this.activatedRoute.snapshot.params.id, catalogDetails)
        .pipe(map((result) => this.transform(result)));
    } else {
      return this.catalogService
        .updateCatalogDetails(
          this.contextService.getMandantIdInstant(),
          this.activatedRoute.snapshot.params.id,
          catalogDetails
        )
        .pipe(map((result) => this.transform(result)));
    }
  }

  getBatchUpdatedCatalogDetails(catalogDetailTreeNode: TreeNode): CatalogDetail {
    return {
      id: catalogDetailTreeNode.data.id,
      name: catalogDetailTreeNode.data.name,
      dbId: catalogDetailTreeNode.data.dbId ?? uuid(),
      katalogId: this.activatedRoute.snapshot.params.id,
      active: catalogDetailTreeNode.data.active,
      mappingIds: Object.keys(catalogDetailTreeNode.data.mappedValues)
        .filter((key) => catalogDetailTreeNode.data.mappedValues[key])
        .map((id) => id.replace(/_/g, '.')),
      childCatalogDetails: catalogDetailTreeNode.children?.map((c) => this.getBatchUpdatedCatalogDetails(c)) ?? [],
      parentCatalogDetailDbId: catalogDetailTreeNode.parent?.data.dbId,
    } as CatalogDetail;
  }

  private transform(result: CatalogDetail[]): TreeNode[] {
    return result.map((c) => {
      return this.getCatalogDetailTree(c);
    });
  }

  private getCatalogDetailTree(c: CatalogDetail): TreeNode {
    return {
      data: {
        ...c,
        deletable: this.activatedRoute.snapshot.data.isMandantSection ?? false,
        mappedValues:
          c.mappingIds.filter((m) => !!m).length > 0
            ? c.mappingIds
                .filter((m) => !!m)
                .reduce(
                  (obj, item) => ({
                    ...obj,
                    [item.replace(/\./g, '_')]: true,
                  }),
                  {}
                )
            : {},
        detail: true,
      },
      expanded: true,
      children: c.childCatalogDetails.map((cd) => this.getCatalogDetailTree(cd)),
    } as TreeNode;
  }
}
