<p-panel [style]="{ 'margin-bottom': '1rem' }" [toggleable]="true" header="Version 1.5">
  <ul>
    <li>Optimierung Verarbeitungsprozess bei der Erstellung eines Zeitbudgets</li>
    <li>Anpassung Bewirtschaftung von Mandanten</li>
    <li>VAR-Verknüpfung auf allen Kundenvariablen eingefügt (für tacs®3)</li>
    <li>Handbuchgenerator für persönliche Variablenhandbücher optimiert</li>
    <li>Optimierung der aktualisierten Anzeige im Sollstellenplan</li>
    <li>Leistungsgewichte bei tacs®Massnahmen definiert und aufgenommen (tacs®3)</li>
    <li>Sortiernummern bei Organisationseinheiten, Statistikcode und Nutzniesser (tacs®3)</li>
    <li>Snomed-CT Verknüpfungen bei tacs®Variablen</li>
    <li>Organisationstyp integriert (tacs®3)</li>
    <li>Leistungsgewichte für Betriebsvariablen mit Massnahmencharakter (tacs®3)</li>
    <li>Schnellerfassung Kunde (tacs®3)</li>
  </ul>
</p-panel>

<p-panel [style]="{ 'margin-bottom': '1rem' }" [toggleable]="true" header="Version 1.4">
  <ul>
    <li>Jobs: Scrollmöglichkeit bei Warnungen und Fehlern eingefügt</li>
    <li>Jobs: beim Import werden doppelte Anstellungen erkannt und ausgewiesen</li>
    <li>KIS-Partner: Technischer Benutzer neu aufgenommen</li>
    <li>KIS-Partner: neue Rechte für diese Rolle erstellt</li>
    <li>
      Mandanten: wenn ein Mandant beendet wird, dann werden auch die Organisationseinheiten, der Variablenkatalog und
      die Variablenzuordnung beendet
    </li>
    <li>Organisationseinheiten: neuen Button "SAB aktiviert" eingefügt (für tacs®3)</li>
    <li>Organisationseinheiten: neuen Button "Schnellerfassung Kunde" eingefügt (für tacs®3)</li>
    <li>Statistikcode: neuer Prüfmechanismus zur eindeutigen ID</li>
    <li>Nutzniesser: neuer Prüfmechanismus zur eindeutigen ID</li>
    <li>
      Variablen Gesamtkatalog: tacs®Massnahmen können zur Deaktivierung gesperrt werden, wenn das entsprechende KIS
      diese tacs®Massnahme zwingend benötigt
    </li>
    <li>Variablen Gesamtkatalog: neue Anzeige bei Betriebsvariablen ohne direkte Parentvariable</li>
    <li>
      Variablenzuordnung: eine zugeordnete Variable wird automatisch beendet, wenn diese im Gesamtkatalog beendet wird.
    </li>
    <li>Variablenzuordnung: ab 20 OE’s muss ausgewählt werden, welche OE’s geladen werden sollen</li>
    <li>Handbuchgenerator: Optimierung bei der Erstellung nach Personalkategorien</li>
    <li>Zeitbudget: Optimierung der Berechnungsbasis</li>
    <li>
      Zeitbudget: ein Zeitbudget ist nur noch für OE’s möglich, die auch Personalstammdaten besitzen und somit Erbringer
      sind
    </li>
    <li>
      Mapping: bei Fremdkatalogen können Inhalte inaktiv gesetzt werden (z.B. bei nicht benötigten nötigen NANDA-I)
    </li>
    <li>Feiertage: Optimierung der Speicherung</li>
    <li>
      Betriebstage: Optimierte Funktionalität der Berechnung und Optimierung der Speicherung bei Mandanten mit vielen
      OE’s
    </li>
    <li>Betriebstage: wenn eine OE beendet wird, so werden automatisch auch die Betriebstage beendet.</li>
  </ul>
</p-panel>

<p-panel [style]="{ 'margin-bottom': '1rem' }" [toggleable]="true" header="Version 1.3">
  <ul>
    <li>Neue Exportmöglichkeit vom Zeitbudget für die Verwendung in einer anderen Applikation</li>
    <li>Optimierung der Merkmalübernahme von Parentvariablen auf Betriebsvariablen</li>
    <li>Datenbankbereinigungen aufgrund mangelhafter Funktionsweisen aus der Zeit der tacs®Administration</li>
    <li>Neue Funktion «Filter zurücksetzen» auf allen Seiten mit Filterauswahl</li>
    <li>
      Optimierte Funktionalität bei der Anlegung oder Beendigung von betriebseigenen Inhalten bei synchronisierten
      Mandanten
    </li>
    <li>Optimierte Performance bei der Bewirtschaftung von Mandanten mit vielen Organisationseinheiten</li>
    <li>Optimierungen in der Bewirtschaftung vom Zeitbudget</li>
    <li>Variablenhandbuch für zukünftige Erfassungen bereits erstellen können</li>
    <li>Optimierung zur Bewirtschaftung der Variablenbeschreibung für Anwenderbetriebe</li>
    <li>Optimierung der Bewirtschaftung zum Sollstellenplan</li>
    <li>Performancesteigerung zur Bewirtschaftung von Mapping-Katalogen</li>
    <li>Allgemeine Performanceoptimierungen</li>
  </ul>
</p-panel>

<p-panel [style]="{ 'margin-bottom': '1rem' }" [toggleable]="true" header="Version 1.2">
  <p-messages severity="info">
    <ng-template pTemplate>
      <i class="fal fa-exclamation-circle"></i>
      <div class="ml-2"><b>Mit dieser Version 1.2 wird die gesamte bisherige tacs®Administration abgelöst.</b></div>
    </ng-template>
  </p-messages>

  <ul>
    <li>
      Personalkategorie: Ergänzung vom Kürzel auf der obersten Bewirtschaftungsmaske, zusätzlicher Darstellungsfilter
    </li>
    <li>
      Personalkosten: neue Bewirtschaftungsmaske mit Historisierung in der Detailmaske, inkl. neuer Exportmöglichkeit
    </li>
    <li>
      Zeitgutschrift: neue Bewirtschaftungsmaske, benötigte OE' können neu selber zusammengestellt werden, inkl. neuer
      Exportmöglichkeit
    </li>
    <li>
      Sollstellenplan: neue Bewirtschaftungsmaske, mit transparenter Historisierung, individuelle OE-Zusammenstellung,
      Ausblendung leerer Zeilen möglich, inkl. neuer Exportmöglichkeit
    </li>
    <li>
      Mapping-Kataloge: Neue Bewirtschaftungsmaske, Bewirtschaftung aus Sicht Fremdkatalog und nach methodischen
      Inhalten möglich
    </li>
    <li>
      Zeitbudget: neue Bewirtschaftungsmaske, Anzeige nach gewählten OE's und/oder Kategorien, neuer Ist-Import nach
      Prozenten, inkl. Exportmöglichkeit
    </li>
  </ul>
</p-panel>

<p-panel [style]="{ 'margin-bottom': '1rem' }" [toggleable]="true" header="Version 1.1">
  <ul>
    <li>
      Statistikcodes Übersicht: neue Übersichtstabelle mit neuer Eröffnungs- und Bewirtschaftungsmaske, Aufnahme der
      Option "Kostenstelle", inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Statistikcodes Zuordnungen: neue Bewirtschaftungsmaske zur Zuordnung der Statistikcodes, Anzeige nach
      Organisationseinheiten, Personalkategorien und Anstellungen, Anzeigereduktionsmöglichkeiten je nach
      Bewirtschaftungsanliegen, gemeinsames Aktivieren und Deaktivieren, mit Gültigkeitsverlauf im Detail jeder
      Zuordnung, inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Nutzniesser Übersicht: neue Übersichtstabelle mit neuer Eröffnungs- und Bewirtschaftungsmaske, Aufnahme der Option
      "Kostenstelle", inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Nutzniesser Zuordnungen: neue Bewirtschaftungsmaske zur Zuordnung der Nutzniesser, Anzeige nach
      Organisationseinheiten, Personalkategorien und Anstellungen, Anzeigereduktionsmöglichkeiten je nach
      Bewirtschaftungsanliegen, gemeinsames Aktivieren und Deaktivieren, mit Gültigkeitsverlauf im Detail jeder
      Zuordnung, inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Variablen Gesamtkatalog: neue Übersicht aller Variablen gemäss Markzuordnung des Mandanten, dynamisch
      bewirtschaftbar mit neuen Sortierfunktionen und umfangreicherer Anzeige weiterer Informationen, transparente
      Detailübersicht pro Variable mit Gültigkeitsverlauf, neue und einfachere Bewirtschaftungsmöglichkeit von
      Betriebsvariablen, die bisherige separate Navigationsseite "Betriebsvariablen" ist nun als Reduktionsfilter in der
      Maske integriert, inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Variablen Zuordnungen: neue und effiziente Bewirtschaftungsmaske zur Zuordnung der Variablen, Anzeige nach
      Organisationseinheiten, Personalkategorien und Anstellungen, Anzeigereduktionsmöglichkeiten je nach
      Bewirtschaftungsanliegen, mit Ein- und Ausblendmöglichkeit von Massnahmen, gemeinsames Aktivieren und
      Deaktivieren, mit Gültigkeitsverlauf im Detail jeder Zuordnung, neue und einfache Möglichkeit zur
      Handbucherstellung auf jeder Ebene, inkl. neuer Exportmöglichkeiten.
    </li>
    <li>
      Variablen Freigabe: Übersicht aller beantragten Betriebsvariablen und Umbenennungen mit Korrespondenzverlauf im
      Detail, Anzeige mit Erstellungsdatum, Ersteller, Status und Typ in der Übersicht, inkl. neuer Exportmöglichkeit.
    </li>
    <li>
      Massnahmenansicht: neue Anzeige aller aktivierten Massnahmen mit den jeweils übergeordneten Detailvariablen, inkl.
      neuer Exportmöglichkeit.
    </li>
    <li>
      Feiertage: neue tabellarische Feiertagsübersicht nach Kalenderjahren, Aktualisierung der gesetzlichen Feiertage
      (Basisimport nach Kanton des Mandanten) oder Übernahme der Vorjahreseinstellungen, inkl. neuer Exportmöglichkeit.
    </li>
    <li>
      Betriebstage: neue Bewirtschaftungsmaske mit der Möglichkeit zur gleichzeitigen Bewirtschaftung von mehreren
      Organisationseinheiten, neue Jahresübersicht im Detailfenster, inkl. neuer Exportmöglichkeit.
    </li>
  </ul>
</p-panel>

<p-panel [toggleable]="true" header="Version 1.0">
  <ul>
    <li>
      Neues Dashboard, welches schrittweise die gesamte tacs®Administration ablöst: bessere Performance, höhere
      Benutzerfreundlichkeit, neue Filtermöglichkeiten und Sortierfunktion, neue Exportmöglichkeiten und Verlinkungen in
      die tacs®Administration bei jenen Navigationspunkten, die noch nicht neu programmiert sind
    </li>
    <li>
      Jobs: deutlich schnelleres und aussagekräftigeres Import Controlling mit transparenter Auflistung von Warnungen
      und Fehlern, neue Exportmöglichkeiten der Fehler und Warnungen
    </li>
    <li>
      Benutzer: neue Benutzerverwaltung mit einfacherem Anlegeprozess, zudem werden neu angelegte Benutzer automatisch
      ins tacs®Reporting übertragen
    </li>
    <li>Mandanten: neue Bewirtschaftungsmaske der Mandanten</li>
    <li>
      Organisationseinheiten: neue Bewirtschaftungsmaske der Organisationseinheiten mit zusätzlichen Informationen wie
      Mitarbeiter- und Kundenstammdatenzuordnung, neue Filtermöglichkeiten und Sortierfunktionen, inkl. neuer
      Exportmöglichkeit
    </li>
    <li>
      Personalkategorien: neue Bewirtschaftungsmaske der Personalkategorien mit neuen Filtermöglichkeiten und
      Sortierfunktion, inkl. neuer Exportmöglichkeit
    </li>
    <li>Stammdaten Kunden: neue Anzeigemaske mit Filtermöglichkeiten und Sortierfunktion, inkl. Exportmöglichkeit</li>
    <li>
      Stammdaten Mitarbeiter: neue Anzeigemaske mit Filtermöglichkeiten und Sortierfunktion, inkl. Exportmöglichkeit
    </li>
    <li>Verlinkung ins tacs®Reporting und wieder zurück ins tacs®Dashbord</li>
    <li>Verlinkung auf die gänzlich neue Hilfeseite, mit allen aktuellen Handbüchern und weiteren Unterlagen</li>
    <li>Neue Funktion zum Passwort wechseln</li>
    <li>Neue Funktion zur Mehrfachauthentifizierung</li>
  </ul>
</p-panel>
