import { Injectable } from '@angular/core';
import { Kanton } from '@entity/kanton';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicHoliday } from '@entity/public-holiday';
import { Catalog, CatalogDetail, MappedValue } from '@entity/catalog';

@Injectable({
  providedIn: 'root',
})
export class MainDataService {
  private readonly baseUrl = `api/1.0/maindata`;

  constructor(private httpClient: HttpClient) {}

  getKantone(): Observable<Kanton[]> {
    return this.httpClient.get<Kanton[]>(`${this.baseUrl}/kantone`);
  }

  getPublicHolidays(year: number): Observable<PublicHoliday[]> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.get<PublicHoliday[]>(`${this.baseUrl}/public-holidays`, { params });
  }

  updatePublicHoliday(holiday: PublicHoliday): Observable<PublicHoliday> {
    return this.httpClient.put<PublicHoliday>(`${this.baseUrl}/public-holidays/${holiday.id}`, holiday);
  }

  deletePublicHoliday(holiday: PublicHoliday): Observable<unknown> {
    const params = new HttpParams().append('year', holiday.date?.getFullYear() + '');
    return this.httpClient.delete<PublicHoliday[]>(`${this.baseUrl}/public-holidays/${holiday.id}`, { params });
  }

  addPublicHoliday(holiday: PublicHoliday): Observable<PublicHoliday> {
    return this.httpClient.post<PublicHoliday>(`${this.baseUrl}/public-holidays`, holiday);
  }

  addLastYearsPublicHoliday(focusYear: number): Observable<unknown> {
    const params = new HttpParams().append('year', focusYear + '');
    return this.httpClient.post<PublicHoliday>(`${this.baseUrl}/add-last-years-public-holiday`, {}, { params });
  }

  getCatalogs(): Observable<Catalog[]> {
    return this.httpClient.get<Catalog[]>(`${this.baseUrl}/catalogs`);
  }

  updateCatalogs(catalogs: Catalog[]): Observable<Catalog[]> {
    return this.httpClient.put<Catalog[]>(`${this.baseUrl}/catalogs`, catalogs);
  }

  getCatalogDetails(catalogId: number): Observable<CatalogDetail[]> {
    return this.httpClient.get<CatalogDetail[]>(`${this.baseUrl}/catalogs/${catalogId}`);
  }

  updateCatalogDetails(catalogId: number, value: CatalogDetail[]): Observable<CatalogDetail[]> {
    return this.httpClient.put<CatalogDetail[]>(`${this.baseUrl}/catalogs/${catalogId}`, value);
  }

  getVariableMappings(catalogId: number, detailId = ''): Observable<MappedValue[]> {
    return this.httpClient.get<MappedValue[]>(`${this.baseUrl}/catalogs/${catalogId}/variables/${detailId}`);
  }

  updateVariableMappings(catalogId: number, detailId: string, mappedValues: MappedValue[]): Observable<MappedValue[]> {
    return this.httpClient.put<MappedValue[]>(
      `${this.baseUrl}/catalogs/${catalogId}/variables/${detailId}`,
      mappedValues
    );
  }

  getMeasureMappings(catalogId: number, detailId = ''): Observable<MappedValue[]> {
    return this.httpClient.get<MappedValue[]>(`${this.baseUrl}/catalogs/${catalogId}/measures/${detailId}`);
  }

  updateMeasureMappings(catalogId: number, detailId: string, mappedValues: MappedValue[]): Observable<MappedValue[]> {
    return this.httpClient.put<MappedValue[]>(
      `${this.baseUrl}/catalogs/${catalogId}/measures/${detailId}`,
      mappedValues
    );
  }

  downloadMappingCatalog(catalogId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.baseUrl}/catalogs/${catalogId}/mapping-xslx-export`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
