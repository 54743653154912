import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, input, Input, ViewChild } from '@angular/core';
import { SharedModule, TreeNode } from 'primeng/api';
import { TreeTable, TreeTableModule } from 'primeng/treetable';
import { AbstractTableComponent } from '@shared/table/abstract-table.component';
import { Column } from '@shared/table/column';
import { TranslateModule } from '@ngx-translate/core';
import { TableCellComponent } from './table-cell/table-cell.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { EllipsisTooltipDirective } from '../ellipsis-tooltip.directive';
import { ButtonModule } from 'primeng/button';
import { ScrollIntoViewDirective } from '../scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TreeTableModule,
    SharedModule,
    MultiSelectModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    ScrollIntoViewDirective,
    ButtonModule,
    EllipsisTooltipDirective,
    TriStateCheckboxModule,
    InputNumberModule,
    InputTextModule,
    TableCellComponent,
    TranslateModule,
  ],
})
export class TreeTableComponent extends AbstractTableComponent implements AfterViewInit {
  @Input()
  pagination = true;

  @Input()
  createLabels: string[] = [];

  deepNestingAllowed = input(false);

  @Input()
  strictFilterMode = true;

  @Input()
  newHeaderGroupMode = false;
  @Input()
  rowsPerPage = 50;
  first = 0;
  @ViewChild('tt')
  private treeTableComponent!: TreeTable;

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit(): void {
    if (!this.pagination) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.treeTableComponent.scrollableViewChild.frozenSiblingBody =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.treeTableComponent.scrollableViewChild.el.nativeElement.previousElementSibling.querySelector(
          '.p-treetable-scrollable-body'
        );
    }
  }

  getTrackByFn(dataKey: string): (index: number, item: AnyValue) => string | number {
    return (index, item) => {
      return item.node.data[dataKey] ?? item.node.data.dbId ?? item.node.data.id ?? item.node.data;
    };
  }

  getColspan(column: Column): number {
    return this.scrollableColumns.filter((c) => (c.level = column.level)).length;
  }

  shouldHaveInvisibleBorderRight(last: boolean, column: Column, rowNode: AnyValue, columnIndex: number): boolean {
    if (last) {
      return false;
    }

    if (rowNode.level === column.level) {
      return false;
    }

    const nextColumn: Column = this.columns[columnIndex + 1];

    if (!nextColumn) {
      return false;
    }

    if (column.level !== nextColumn.level && rowNode.level === nextColumn.level) {
      return false;
    }

    return rowNode.level !== column.level;
  }

  hasMatchingLevel(column: Column, rowNode: AnyValue): boolean {
    return column?.level === rowNode.level;
  }

  expand(tt: TreeTable): void {
    if (this.pagination) {
      setTimeout(() => {
        // @ts-expect-error scrollBodyViewChild seems to be missing in the type definition
        const scrollableFrozenViewChild = tt.scrollableFrozenViewChild!['scrollBodyViewChild'];

        // @ts-expect-error scrollBodyViewChild seems to be missing in the type definition
        const scrollableViewChild = tt.scrollableViewChild!['scrollBodyViewChild'];

        scrollableFrozenViewChild.nativeElement.scrollTop = scrollableViewChild.nativeElement.scrollTop;
      });
    }
  }

  getHeaders(): { title: string; colSpan: number }[] {
    return this.headerGroups[0]
      .map((headerGroup, index) => ({
        title: headerGroup.title,
        colSpan: this.scrollableColumns.filter((c) => c.level === index).length,
      }))
      .filter((h) => h.colSpan > 0);
  }

  getFlatTree(nodes: TreeNode[]): TreeNode[] {
    const result: AnyValue[] = [];

    nodes.forEach((t) => {
      result.push(t);
      if (t.children) {
        this.getFlatTree(t.children).forEach((c) => result.push(c));
      }
    });

    return result;
  }

  getFlatTreeData(nodes: TreeNode[]): AnyValue[] {
    return this.getFlatTree(nodes).map((n) => n.data);
  }

  create(parentNode: TreeNode | null = null): void {
    const treeNode = this.tableService?.createEntry(this.value, parentNode);
    this.markAsNew(treeNode);
    if (parentNode === null) {
      this.value.push(treeNode);
      this.changes.added.push(treeNode);
    } else {
      const [parentNodeRef] = this.getFlatTree(this.value).filter((node) => node.data === parentNode?.data);
      treeNode.parent = parentNodeRef;
      parentNodeRef.children = [...(parentNodeRef.children ?? []), treeNode];
      parentNodeRef.expanded = true;

      const root = this.getRootNode(parentNodeRef);
      if (!this.changes.added.includes(root) && !this.changes.updated.includes(root)) {
        this.changes.updated.push(root);
      }
    }
    this.value = [...this.value];
    this.updateButtons();
    if (!parentNode) {
      this.scrollToNewNode(treeNode);
    }
  }

  update(event: AnyValue, treeNode: TreeNode, field: string): void {
    const changes = `_changes`;
    if (treeNode.data[changes] === undefined) {
      treeNode.data[changes] = {};
    }

    if (treeNode.data[changes][field] === event) {
      treeNode.data[changes][field] = undefined;
    } else if (treeNode.data[field] !== event) {
      treeNode.data[changes][field] = this.getData(treeNode.data, field) ?? null;
    }

    const oldData = this.getData(treeNode.data, field);
    this.setData(treeNode.data, field, event);
    this.tableService?.onDataChange(event, oldData, field, treeNode, this.value);

    const root = this.getRootNode(treeNode);
    if (!this.changes.added.includes(root) && !this.changes.updated.includes(root)) {
      this.changes.updated.push(root);
    }
    this.updateButtons();
  }

  // the input treeNode is the view of a filtered treeNode in the form and is not the same as the treeNode in the original tree table.
  delete(treeNode: TreeNode, rowNodeLevel: number): void {
    const root = this.getRootNode(treeNode);

    if (rowNodeLevel === 0 && root.data.dbId === treeNode.data.dbId) {
      if (!this.changes.added.includes(root)) {
        this.changes.deleted.push(root);
      }
      this.changes.added.remove(root);
      this.changes.updated.remove(root);
      this.value.remove(root);
    } else {
      this.deleteNodeFromTree(root, treeNode, rowNodeLevel);
    }

    this.updateButtons();
    this.value = [...this.value];
  }

  private deleteNodeFromTree(currentNode: TreeNode, targetNode: TreeNode, targetLevel: number, currentLevel = 0): void {
    if (!currentNode.children) {
      return;
    }

    if (currentLevel + 1 === targetLevel) {
      currentNode.children = currentNode.children.filter((child: TreeNode) => child.data.dbId !== targetNode.data.dbId);

      if (!this.changes.added.includes(currentNode) && !this.changes.updated.includes(currentNode)) {
        this.changes.updated.push(currentNode);
      }
      return;
    }

    currentNode.children.forEach((child: TreeNode) => {
      this.deleteNodeFromTree(child, targetNode, targetLevel, currentLevel + 1);
    });
  }

  resetFilter(): void {
    super.resetFilter();
    if (!this.treeTableComponent) return;
    this.treeTableComponent.filters = this.filters;
    this.treeTableComponent._filter();
  }

  protected getRootNode(treeNode: TreeNode): TreeNode {
    let parentNode = treeNode.parent;
    let rootNode = treeNode;
    while (parentNode) {
      rootNode = parentNode;
      parentNode = parentNode.parent;
    }
    return this.value.filter((n) => n.data === rootNode.data)[0];
  }

  protected markAsNew(row: AnyValue): void {
    super.markAsNew(row);
    row.children?.forEach((c: AnyValue) => this.markAsNew(c));
  }

  protected cleanErrorsAndChanges(row: AnyValue): void {
    super.cleanErrorsAndChanges(row);
    row.children?.forEach((child: TreeNode[]) => this.cleanErrorsAndChanges(child));
  }

  protected getMetaStore(row: AnyValue): AnyValue {
    return row.data;
  }

  protected addError(row: AnyValue, error: { field: string; error: string }): void {
    const field = error.field.substr(0, 1).toLowerCase() + error.field.substr(1);
    if (row.data[field] !== undefined) {
      if (this.getMetaStore(row)._errors === undefined) {
        this.getMetaStore(row)._errors = {};
      }
      this.getMetaStore(row)._errors[field] = error.error;
    } else {
      // If it is a tree table, the field could be in a child.
      row.children?.forEach((child: TreeNode[]) => this.addError(child, error));
    }
  }

  protected validate(): boolean {
    return this.validateTree(this.value);
  }

  protected validateTree(rows: TreeNode[]): boolean {
    let valid = true;

    rows.forEach((row: AnyValue) => {
      if (row.children && !this.validateTree(row.children)) {
        valid = false;
      }
      const errors: AnyValue = {};
      this.columns.forEach((column) => {
        if (column.validator) {
          const validator = column.validator;
          const result = validator(this.getData(row.data, column.field), row);
          if (result) {
            valid = false;
            errors[column.field] = result;
          }
        }
        this.getMetaStore(row)._errors = errors;
      });
    });

    return valid;
  }

  private scrollToNewNode(treeNode: TreeNode): void {
    this.treeTableComponent.first = Math.floor(this.value.indexOf(treeNode) / this.rowsPerPage) * this.rowsPerPage;
    this.treeTableComponent.scrollTo({ top: 10000000 });
  }

  getNestedNodeLabel(rowNodeLevel: number): string {
    return this.deepNestingAllowed()
      ? this.createLabels[this.createLabels.length - 1]
      : this.createLabels[rowNodeLevel + 1];
  }
}
