<p-treeTable
  #tt
  (onFilter)="onFilter($event)"
  (onLazyLoad)="loadLazyData($event)"
  (onNodeCollapse)="expand(tt)"
  (onNodeExpand)="expand(tt)"
  (selectionChange)="selectionChange.emit($event)"
  [columns]="scrollableColumns"
  [dataKey]="dataKey"
  [filterMode]="strictFilterMode ? 'strict' : 'lenient'"
  [first]="first"
  [frozenColumns]="frozenColumns"
  [frozenWidth]="frozenWidth"
  [lazy]="lazy"
  [loading]="loading"
  [paginator]="pagination"
  [rowTrackBy]="getTrackByFn(dataKey)"
  [rows]="rowsPerPage"
  [scrollable]="true"
  [selectionMode]="selectable ? 'multiple' : 'none'"
  [selection]="selection"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [totalRecords]="totalRecords"
  [value]="value"
  [virtualScrollItemSize]="50"
  [virtualScroll]="!pagination"
  scrollHeight="flex"
  styleClass="flex-table p-treetable-sm p-treetable-gridlines p-treetable-striped">
  <!-- With virtual scrolling enabled the frozen column doesn't scroll at all
  Bug report: https://github.com/primefaces/primeng/issues/11722
  if/when issue is resolved, enable virtual scrolling again and compare performance -->
  <ng-template pTemplate="caption">
    <div class="toolbar">
      @if (showFrozenColumnSelection) {
        <p-multiSelect
          optionLabel="header"
          (onChange)="frozenColumnsChange()"
          [(ngModel)]="frozenColumns"
          [options]="columns"
          [style]="{ minWidth: '300px' }"></p-multiSelect>
      }

      @if (hasFocusDate) {
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          yearRange="2000:2030"
          (ngModelChange)="setFocusDate($event)"
          (onSelect)="setFocusDate($event)"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [ngModel]="focusDate"
          [showButtonBar]="true"
          [showIcon]="true"
          [style]="{ width: '140px' }"></p-calendar>
      }

      @if (hasFocusYear) {
        <p-dropdown
          appScrollIntoView
          appendTo="body"
          (ngModelChange)="focusYearChange.emit($event)"
          [(ngModel)]="focusYear"
          [options]="focusYearRange"
          [style]="{ width: '140px' }"></p-dropdown>
      }

      @if (editable) {
        <p-button
          icon="fal fa-save"
          type="button"
          (onClick)="save()"
          [disabled]="isSaveDisabled"
          [label]="'ACTIONS.SAVE' | translate"></p-button>
        <p-button
          icon="fal fa-times"
          type="button"
          (onClick)="cancel()"
          [disabled]="isCancelDisabled"
          [label]="'ACTIONS.CANCEL' | translate"></p-button>
        @if (isCreateAllowed) {
          <p-button
            icon="fal fa-plus"
            type="button"
            (onClick)="create()"
            [disabled]="isCreateDisabled"
            [label]="createLabels[0] | translate"></p-button>
        }
      }

      @for (button of toolbarButtons; track button) {
        <p-button
          type="button"
          (onClick)="button.onClick.emit($event)"
          [disabled]="button.disabled"
          [icon]="button.icon"
          [label]="button.labelKey | translate"></p-button>
      }

      @if (selectable) {
        <p-button
          icon="fal fa-square"
          type="button"
          (onClick)="clearSelection()"
          [disabled]="selection?.length === 0"
          [label]="'ACTIONS.CLEAR_SELECTION' | translate: { count: selection.length }"></p-button>
      }

      <div class="spacer"></div>

      <p-button
        (onClick)="resetFilter()"
        [label]="'GLOBAL.RESET_FILTER' | translate"
        icon="fal fa-undo"
        iconPos="left"
        styleClass="p-button-outlined"
        type="button"></p-button>

      <p-button
        (onClick)="exportCSV(getFlatTreeData(tt.filteredNodes || (tt.value ?? [])))"
        icon="fal fa-file-alt"
        iconPos="left"
        label="CSV"
        styleClass="p-button-outlined"
        type="button"></p-button>

      <p-button
        (onClick)="exportExcel(getFlatTreeData(tt.filteredNodes || (tt.value ?? [])))"
        icon="fal fa-file-excel"
        iconPos="left"
        label="Excel"
        styleClass="p-button-outlined"
        type="button"></p-button>
    </div>
  </ng-template>

  <ng-template let-columns pTemplate="header">
    @if (!newHeaderGroupMode) {
      @if (headerGroups.length > 0 && columns === scrollableColumns) {
        <tr style="height: 33px">
          @for (header of getHeaders(); track header) {
            <th style="position: relative" [colSpan]="header.colSpan">
              <div appEllipsisTooltip class="header-ellipsis">{{ header.title }}</div>
            </th>
          }
          @if (actionColumnWidthInPx) {
            <th></th>
          }
        </tr>
      }
      @if (headerGroups.length > 0 && columns === frozenColumns) {
        <tr style="height: 33px">
          <th [colSpan]="frozenColumns.length"></th>
        </tr>
      }
    }

    @if (newHeaderGroupMode) {
      @if (headerGroups.length > 0 && columns === scrollableColumns) {
        @for (groups of calculatedHeaderGroups; track groups) {
          <tr style="height: 33px">
            @for (group of groups; track group) {
              <th style="position: relative" [colSpan]="group.colSpan" [rowSpan]="group.rowSpan || 1">
                <div appEllipsisTooltip class="header-ellipsis">{{ group.title }}</div>
              </th>
            }
          </tr>
        }
      }
      @if (headerGroups.length > 0 && columns === frozenColumns) {
        @for (groups of headerGroups; track groups) {
          <tr style="height: 33px">
            <th [colSpan]="frozenColumns.length"></th>
          </tr>
        }
      }
    }

    <tr style="height: 33px">
      @for (col of columns; track col) {
        <th style="position: relative" [style.min-width]="col.minWidth || col.width" [style.width]="col.width">
          <div appEllipsisTooltip class="header-ellipsis">{{ col.header }}</div>
          @if (!col.disableFilterAndSort) {
            <p-treeTableSortIcon
              (click)="tt.sort({ field: col.field })"
              [field]="col.field"
              [style]="{ position: 'absolute', right: '15px', top: '7px' }"></p-treeTableSortIcon>
          }
        </th>
      }
      @if (actionColumnWidthInPx && columns === scrollableColumns) {
        <th></th>
      }
    </tr>
    <tr style="height: 53px">
      @for (col of columns; track col) {
        <th>
          @if (!col.disableFilterAndSort) {
            @switch (col.filterType) {
              @case ('date') {
                <p-calendar
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                  (ngModelChange)="tt.filter($event, col.field, 'date')"
                  [ngModel]="getDateFilter(col.field)"
                  [showButtonBar]="true"></p-calendar>
              }
              @case ('boolean') {
                <div style="width: 100%; text-align: center">
                  <p-triStateCheckbox
                    (onChange)="tt.filter($any($event.value), col.field, 'true')"
                    [ngModel]="filters[col.field]?.value"></p-triStateCheckbox>
                </div>
              }
              @case ('percent') {
                <p-inputNumber
                  (onInput)="tt.filter(toNumber($event) / 100 + '', col.field, 'equals')"
                  [maxFractionDigits]="2"
                  [max]="100"
                  [minFractionDigits]="2"
                  [min]="0"
                  [showButtons]="false"
                  [ngModel]="filters[col.field]?.value"
                  [suffix]="'%'"></p-inputNumber>
              }
              @case ('time') {
                <p-calendar
                  appendTo="body"
                  (ngModelChange)="tt.filter($event, col.field, 'equals')"
                  [ngModel]="getDateFilter(col.field)"
                  [timeOnly]="true"></p-calendar>
              }
              @case ('dateRangeAssignment') {
                <div style="width: 100%; text-align: center">
                  <p-triStateCheckbox
                    (onChange)="tt.filter($any($event.value), col.field, 'true')"
                    [ngModel]="filters[col.field]?.value"></p-triStateCheckbox>
                </div>
              }
              @default {
                @if (col.options) {
                  <p-dropdown
                    appScrollIntoView
                    appendTo="body"
                    (onChange)="tt.filter($event.value, col.field, 'equals')"
                    [ngModel]="filters[col.field]?.value"
                    [filter]="true"
                    [filterBy]="'label'"
                    [options]="col.options"
                    [placeholder]="'-'"
                    [showClear]="true"></p-dropdown>
                } @else {
                  <input
                    pInputText
                    type="text"
                    [ngModel]="filters[col.field]?.value"
                    (input)="tt.filter($any($event.target).value, col.field, 'contains')" />
                }
                <ng-template #text>
                  <input
                    pInputText
                    type="text"
                    [ngModel]="filters[col.field]?.value"
                    (input)="tt.filter($any($event.target).value, col.field, 'contains')" />
                </ng-template>
              }
            }
          }
        </th>
      }
      @if (actionColumnWidthInPx && columns === scrollableColumns) {
        <th
          [style.max-width]="actionColumnWidthInPx"
          [style.min-width]="actionColumnWidthInPx"
          [style.width]="actionColumnWidthInPx"></th>
      }
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-node="node" let-rowData="rowData" let-rowNode pTemplate="body">
    <tr [style.pointer-events]="node.selectable === false ? 'none' : 'all'" [ttSelectableRow]="rowNode">
      @for (column of columns; track column; let columnIndex = $index; let last = $last) {
        <td
          style="height: 50px"
          [style]="rowData['_style_' + column.field]"
          [style.min-width]="column.minWidth || column.width"
          [style.width]="column.width"
          [style.max-width]="column.width"
          [class.invisible-border-right]="
            shouldHaveInvisibleBorderRight(last, column, rowNode, columnIndex + frozenColumns.length) &&
            headerGroups.length > 0 &&
            !newHeaderGroupMode
          "
          [class.not-matching-level]="
            !hasMatchingLevel(column, rowNode) && headerGroups.length > 0 && !newHeaderGroupMode
          ">
          @if (hasMatchingLevel(column, rowNode) || headerGroups.length === 0 || newHeaderGroupMode) {
            <div class="table-cell">
              @if (columnIndex === 0 && frozenColumns.length === 0) {
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              }
              <app-table-cell
                (valueChange)="update($event, node, column.field)"
                [changed]="rowData._changes && rowData._changes[column.field] !== undefined"
                [column]="column"
                [editable]="
                  column.editable &&
                  ((column.onlyInitialModificationAllowed && rowData._new) || !column.onlyInitialModificationAllowed) &&
                  isEditable(node, column)
                "
                [error]="rowData._errors && rowData._errors[column.field]"
                [options]="column.options"
                [required]="column.required"
                [route]="getRouterLink(column.route, rowData)"
                [url]="getUrl(rowData, column.url)"
                [row]="node"
                [type]="column.filterType"
                [value]="getData(rowData, column.field)"></app-table-cell>
            </div>
          }
        </td>
      }
      @if (actionColumnWidthInPx) {
        <td
          style="height: 50px"
          [style.max-width]="actionColumnWidthInPx"
          [style.min-width]="actionColumnWidthInPx"
          [style.width]="actionColumnWidthInPx">
          @if (editable) {
            @if (rowData.deletable === null || rowData.deletable === undefined || rowData.deletable === true) {
              <p-button
                icon="fal fa-trash"
                styleClass="p-button-danger"
                type="button"
                (onClick)="delete(rowNode.node, rowNode.level)"
                [label]="'ACTIONS.DELETE' | translate"
                [style]="{ 'margin-right': '0.5rem' }"></p-button>
            }
            @if (isCreateAllowed && (rowNode.level + 1 < createLabels.length || deepNestingAllowed())) {
              <p-button
                icon="fal fa-plus"
                styleClass="p-button-info"
                type="button"
                (onClick)="create(rowNode.node)"
                [label]="getNestedNodeLabel(rowNode.level) | translate"
                [style]="{ 'margin-right': '0.5rem' }"></p-button>
            }
          }
          @for (button of actionButtons; track button) {
            @if (
              (!button.displayIf || getData(rowNode, button.displayIf)) &&
              (!button.displayIfNot || !getData(rowNode, button.displayIfNot))
            ) {
              <p-button
                type="button"
                (onClick)="button.onClick.emit({ event: $event, row: rowNode.node })"
                [disabled]="button.disabled"
                [icon]="button.icon"
                [label]="button.labelKey | translate"
                [styleClass]="'p-button-' + button.type"
                [style]="{ 'margin-right': '0.5rem' }"></p-button>
            }
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-node="node" let-rowData="rowData" let-rowNode pTemplate="frozenbody">
    <tr>
      @for (column of columns; track column; let columnIndex = $index; let last = $last) {
        <td
          style="height: 50px"
          [style]="rowData['_style_' + column.field]"
          [style.min-width]="column.minWidth || column.width"
          [style.width]="column.width"
          [class.not-matching-level]="
            !hasMatchingLevel(column, rowNode) && headerGroups.length > 0 && !newHeaderGroupMode
          ">
          <div class="table-cell">
            @if (columnIndex === 0 && frozenColumns.length > 0) {
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            }
            @if (hasMatchingLevel(column, rowNode) || headerGroups.length === 0 || newHeaderGroupMode) {
              <app-table-cell
                (valueChange)="update($event, node, column.field)"
                [changed]="rowData._changes && rowData._changes[column.field] !== undefined"
                [column]="column"
                [editable]="
                  column.editable &&
                  ((column.onlyInitialModificationAllowed && rowData._new) || !column.onlyInitialModificationAllowed) &&
                  isEditable(node, column)
                "
                [error]="rowData._errors && rowData._errors[column.field]"
                [options]="column.options"
                [required]="column.required"
                [type]="column.filterType"
                [value]="getData(rowData, column.field)"></app-table-cell>
            }
          </div>
        </td>
      }
    </tr>
  </ng-template>

  @if (footer; as columns) {
    <ng-template pTemplate="footer" let-columns>
      <tr>
        @for (col of columns; track col) {
          <td
            [style.max-width]="col.width"
            [style.width]="col.width"
            [style.min-width]="col.minWidth || col.width"
            [style.color]="col.footerColor">
            @if (col.footerKey) {
              {{ col.footerKey | translate }}
            }
            @if (col.footer) {
              {{ col.footer() }}
            }
          </td>
        }
      </tr>
    </ng-template>
  }
</p-treeTable>
